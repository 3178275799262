<template>
  <div
    class="q-mt-md"
    style="width: 100%;"
  >
    <form-builder :schema="mainSchema" />

    <form-builder :schema="schema" />

    <div class="text-center border-top q-pt-md">
      <q-btn
        color="light-blue-9"
        style="min-width: 30%;"
        text-color="white"
        :label="$t('Connect')"
        no-caps
        unelevated
        @click="handleConnect"
      />
    </div>
  </div>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

// Helpers
import { convertSourceIntegration } from '../../helpers/helpers'

export default {
  name: 'BaseSourceForm',
  emits: ['submit'],
  props: {
    integration: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      source: {
        handler: null,
        name: null,
        settings: {}
      }
    }
  },
  computed: {
    mainSchema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                field: 'name',
                value: this.source.name,
                label: this.$t('Name'),
                required: true,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: name => {
                  this.source.name = name
                }
              }
            ]
          }
        ]
      }
    },
    schema () {
      if (!this.integration) {
        return { groups: [] }
      }

      const groups = Object.keys(this.integration.settings || {}).reduce((acc, key) => {
          let fields = [];
          if (this.integration.settings && Array.isArray(this.integration.settings[key])) {
              fields = this.integration.settings[key].map(field => {
                return this.$utils.field.create(this.source.settings, field)
              })
          }

          acc.push({
          legend: '',
          styleClasses: 'row',
          fields
        })

        return acc
      }, [])

      return { groups }
    }
  },
  methods: {
    ...mapMutations([
      'setSources'
    ]),
    handleConnect () {
      const convertedIntegration = convertSourceIntegration(this.source)
      convertedIntegration.handler = this.integration.extId

      return this.$service.iSource.save(convertedIntegration)
        .then(item => {
          this.setSources([item, ...this.sources])
          this.$emit('submit', item)
        })
    }
  }
}
</script>
