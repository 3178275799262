<template>
  <div class="q-pa-sm">
    <q-card>
      <q-card-section>
        <div
            v-if="apps.length <= 0"
            class="row items-center justify-center text-center text-subtitle1"
            style="height: calc(100vh - 130px);"
        >
          {{ $t('No founded apps') }}
        </div>

        <div class="row items-center justify-center">
          <div
              v-for="item in apps"
              :key="item.id"
              class="col-auto q-pa-md"
          >
            <q-card
                class="q-pa-sm relative-position q-mr-xl"
                style="width: 11vw; height: 11vw; max-width: 250px; max-height: 250px;"
                @click="handleApp(item)"
            >
              <img
                  v-if="item.image"
                  style="height: 100%; width: 100%; object-fit: contain;"
                  :src="item.image"
                  :alt="item.name"
              >
              <div
                  v-else
                  class="row justify-center items-center full-height text-center text-h6"
              >
                {{ $t('No image') }}
              </div>

              <div class="badge-container">
                <q-badge
                    v-if="isAdministrator"
                    flat
                    @click.stop="handleOpen(item)"
                    class="q-mx-xs"
                >
                  <q-icon name="edit" size="11px"/>
                </q-badge>

                <q-badge
                    v-if="this.$props.type === '7'"
                    :color="hasSourcesForIntegration(item.id) ? 'green' : 'yellow-9'"
                    :label="hasSourcesForIntegration(item.id) ? $t('Connected') : $t('Connect')"
                    class="q-mr-xs"
                />
                <q-badge
                    v-else
                    :color="hasConnectionsForIntegration(item.dsid) ? 'green' : 'yellow-9'"
                    :label="hasConnectionsForIntegration(item.dsid) ? $t('Connected') : $t('Connect')"
                    class="q-mr-xs"
                />
              </div>
            </q-card>
          </div>
        </div>
      </q-card-section>
    </q-card>

    <integration-modal ref="integrationModal"/>
  </div>
</template>

<style scoped>
.relative-position {
  position: relative;
}

.badge-container {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-wrap: nowrap;
  margin: 0.5rem;
  align-items: center;
}
</style>


<script>
// Directives
import { ObserveVisibility } from 'vue-observe-visibility'

// Components
import IntegrationModal from '../modals/IntegrationModal.vue'
import AppModal from '../modals/AppModal.vue'

// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'IntegrationGrid',
  components: {
    IntegrationModal,
    AppModal
  },
  props: {
    type: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    }
  },
  directives: {
    ObserveVisibility
  },
  data () {
    return {
      apps: [],
      appsPage: 0,
      appsTotalPages: 1,
      field: 'handler',
      value: 'extId'
    }
  },
  computed: {
    ...mapGetters([
      'isAdministrator',
      'appOptions',
      'hasSourcesForIntegration',
      'hasConnectionsForIntegration'
    ]),
  },
  beforeMount () {
    this.loadItems(this.$props.type)
  },
  methods: {
    ...mapMutations([
      'setApp'
    ]),
    loadItems(cat) {
      const query = {
        page: 1,
        per_page: 50,
        filter: [
          { type: 'eq', field: 'state', value: 'active' },
          { type: 'eq', field: 'category', value: cat }
        ]
      };

      return this.$service.iIntegrationService.getAll(query)
        .then(({ items, page, totalPages }) => {
          this.appsPage = page;
          this.appsTotalPages = totalPages;

          this.apps = items;
          if (this.$props.type === '6') {
            this.apps.forEach(item => {
              this.loadDeliveryService(item.extId)
                .then(result => {
                  item.dsid = result
                })
            });
            this.field = 'deliveryService'
            this.value = 'dsid'
          }
        })
    },
    loadDeliveryService(extId) {
      const query = {
        per_page: 5,
        page: 1,
        filter: [
          { type: 'eq', field: 'extId', value: extId }
        ]
      };

      return this.$service.deliveryService.getAll(query)
        .then(({ items }) => {
          return items[0].id
        })
    },
    handleOpen (app) {
      this.$refs.appModal.open(app)
    },
    handleApp (app) {
      this.setApp(app)
      this.$router.push({
        path: `/apps/${this.$props.link}`,
        query: {
          page: 1,
          per_page: 50,
          filter: [
            { type: 'in', field: 'state', values: ['active', 'inactive'] },
            { type: 'eq', field: this.field, value: app[this.value] }
          ]
        }
      })
    }
  }
}
</script>
