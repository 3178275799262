<template>
  <div
    class="q-mt-md"
    style="width: 100%;"
  >
    <form-builder :schema="mainSchema" />

    <form-builder :schema="schema" />
  </div>
</template>

<script>
export default {
  name: 'IntegrationForm',
  emits: ['change'],
  props: {
    integration: {
      type: Object,
      required: true
    },
    model: {
      type: [Object, Array],
      default () {
        return {}
      }
    },
    isDS: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  computed: {
    mainSchema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                field: 'name',
                value: this.model.name,
                label: this.$t('Name'),
                required: true,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: name => {
                  this.model.name = name
                  this.$emit('change', this.model)
                }
              },
              {
                if: this.isDS,
                type: 'multiselect',
                label: this.$t('Sender'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                field: 'sender',
                value: this.model._embedded.sender,
                customLabel: (row) => {
                  if (row && typeof row === 'object') {
                    return `${row.name || this.$t('No name')} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.sender.getAll(query)
                },
                onChange: sender => {
                  this.model._embedded.sender = sender
                  this.$emit('change', this.model)
                }
              }
            ]
          }
        ]
      }
    },
    schema () {
      if (!this.integration) {
        return { groups: [] }
      }

      const groups = Object.keys(this.integration.settings || {}).reduce((acc, key) => {
          let fields = [];
          if (this.integration.settings && Array.isArray(this.integration.settings[key])) {
              fields = this.integration.settings[key].map(field => {
                const newField = this.$utils.field.create(this.model.settings, field)

                newField.watch = () => {
                    this.$emit('change', this.model)
                }

                return newField
            })
          }

        acc.push({
          legend: '',
          styleClasses: 'row',
          fields
        })

        return acc
      }, [])

      return { groups }
    }
  }
}
</script>
