<template>
  <div>
    <div>
      <div class="row">
        <div
          v-for="item in loadedSourceIntegrations"
          :key="item.id"
          class="col-6 col-md-3 q-pa-sm"
          :class="getIsVisible(item)"
        >
          <div class="source-card full-height">
            <div
              class="source-card__head text-center q-pa-sx"
              style="height: 150px"
            >
              <img
                v-if="item.image"
                style="height: 100%; width: 100%; object-fit: contain;"
                :src="item.image"
                :alt="item.name"
              >

              <div
                v-else
                class="row justify-center items-center text-center full-height text-subtitle1"
              >
                {{ item.name }}
              </div>
            </div>

            <div class="source-card__body">
              <div class="full-width">
                <h5 class="q-my-none">
                  {{ item.name }}
                </h5>

                <q-btn
                  color="light-blue-9"
                  text-color="white"
                  size="sm"
                  :label="$t('Setup')"
                  no-caps
                  unelevated
                  @click="handleSetup(item)"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!isDisabledObs"
          v-observe-visibility="loadNextSourceIntegrations({ page: sourceIntegrationsPage + 1, search, per_page: 25, filter: [{ type: 'in', field: 'state', values: ['active', 'inactive'] }] })"
        />

        <div
          v-if="isLoading"
          class="text-primary col-12 row fit justify-center items-center"
          style="min-height: 260px;"
        >
          <q-spinner
            color="light-blue-9"
            size="3rem"
          />
        </div>
      </div>

      <source-modal
        ref="sourceModal"
        @submit="handleSubmit"
      />
    </div>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Directives
import { ObserveVisibility } from 'vue-observe-visibility'

// Components
import Search from '../search/Search.vue'
import SourceModal from './SourceModal.vue'
import FilterCollapse from '../filters/FilterCollapse.vue'

export default {
  name: 'SourceIntegration',
  emits: ['submit'],
  components: {
    Search,
    SourceModal,
    FilterCollapse
  },
  directives: {
    ObserveVisibility
  },
  data () {
    return {
      isOpen: false,
      loadedSourceIntegrations: [],
      statuses: [
        { id: 'active', title: 'Active' },
        { id: 'inactive', title: 'Inactive' }
      ],
      activatedFields: [
        'state',
        'id',
        'name'
      ],
      source: null,
      search: '',
      isLoading: false,
      filter: [
          { type: 'in', field: 'state', values: ['active', 'inactive'] },
          { type: 'eq', field: 'category', value: '7' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'sourceIntegrations',
      'sourceIntegrationsPage',
      'sourceIntegrationsTotalPages',
      'totalSourceIntegrationsNumber',
      'sources'
    ]),
    isDisabledObs () {
      if (this.sourceIntegrationsPage >= this.sourceIntegrationsTotalPages) {
        return true
      }

      return this.isLoading
    }
  },
  watch: {
    sourceIntegrations () {
      this.handleSourceIntegrationsChange()
    }
  },
  mounted () {
    const query = {
      page: 1,
      per_page: 25,
      filter: []
    }

    this.loadNextSourceIntegrations(query)
  },
  methods: {
    ...mapActions([
      'loadSourceIntegrations'
    ]),
    ...mapMutations([
      'addErrorNotification',
      'setApp'
    ]),
    handleFiltersSubmit (filter) {
      this.filter = filter

      if (!this.filter.find(x => x.field === 'state')) {
        this.filter.push({ type: 'in', field: 'state', values: ['active', 'inactive'] })
      }

      this.isOpen = false

      return this.loadNextSourceIntegrations({ per_page: 25, page: 1 })
    },
    toggleFilters () {
      this.isOpen = !this.isOpen
    },
    handleSourceIntegrationsChange () {
      if (this.sourceIntegrationsPage <= 1) {
        this.loadedSourceIntegrations = this.sourceIntegrations
      } else {
        this.loadedSourceIntegrations = [
          ...this.loadedSourceIntegrations,
          ...this.sourceIntegrations
        ]
      }
    },
    loadNextSourceIntegrations (query) {
      this.isLoading = true

      query.filter = this.filter

      return this.loadSourceIntegrations(query)
        .then(() => {
          this.isLoading = false
        })
    },
    getIsVisible (item) {
      return item.id === (this.source || {}).id
        ? 'd-none'
        : ''
    },
    handleSetup (source) {
      this.setApp(source)
      this.$router.push(`/apps/ecommerce/entity/${source.adapter}/${source.id}/0/`)
    },
    handleSubmit (item) {
      this.$emit('submit', item)
    },
    handleSearchSubmit (search) {
      this.search = search

      const query = {
        page: 1,
        per_page: 25,
        search: this.search,
        filter: []
      }

      return this.loadNextSourceIntegrations(query)
    }
  }
}
</script>
