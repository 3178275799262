<template>
  <q-dialog
    v-model="isOpen"
    :maximized="false"
  >
    <q-card
      class="relative"
      style="min-width: 40vh; max-width: 100vw;"
    >
      <q-card-section class="row items-center q-pb-none">
        <q-btn
          v-if="slide !== 'main'"
          text-color="dark"
          size="sm"
          icon="arrow_back"
          no-caps
          flat
          unelevated
          @click="handleBack(this.slide)"
        />

        <q-space />

        <q-btn
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          flat
          unelevated
          @click="close(this.slide)"
        />
      </q-card-section>

      <q-carousel
        v-if="!this.orders"
        :model-value="slide"
        transition-prev="scale"
        transition-next="scale"
        swipeable
        animated
        height="100%"
        width="100%"
      >
        <q-carousel-slide
          name="main"
          class="q-pt-none"
        >
          <h4 class="q-mt-none text-center">
            {{ $t('Import excel') + ':' }}
          </h4>

          <div class="row justify-center item-center">
            <div
              v-for="item in slides"
              :key="item.id"
              class="col-4 q-px-sm"
              style="min-height: 200px; min-width: 300px;"
            >
              <q-card
                class="fit card--clickable"
                @click=this.handleModal(item.id)
              >
                <q-card-section class="row fit text-center items-center justify-center">
                  <div>
                    <q-icon
                      :name="item.icon"
                      size="2rem"
                    />

                    <h5 class="q-my-none">
                      {{ $t(item.title) }}
                    </h5>
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </div>
        </q-carousel-slide>
      </q-carousel>
      <order-import v-if="this.orders"/>
      <product-import v-if="this.products"/>
    </q-card>
  </q-dialog>
  <delivery-request-import ref="shipmentsModal"/>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

// Components
import OrderImport from '../imports/OrderImport.vue';
import DeliveryRequestImport from '../imports/DeliveryRequestImport.vue';
import ProductImport from '../imports/OffersImport.vue';

export default {
  name: 'AllImportModal',
  components: {
    OrderImport,
    DeliveryRequestImport,
    ProductImport
  },
  data () {
    return {
      isOpen: false,
      slide: 'main',
      slides: [
        {
          id: 'orders',
          title: 'Orders',
          icon: 'qr_code'
        },
        {
          id: 'shipments',
          title: 'Shipments',
          icon: 'local_shipping'
        },
        {
          id: 'products',
          title: 'Products',
          icon: 'inventory_2'
        }
      ],
        orders: false,
        products: false,
    }
  },
  methods: {
    ...mapMutations([
      'addOrderToList'
    ]),
    handleModal (modal) {
      this.slide = modal
      if (modal === 'shipments') {
        this.$refs.shipmentsModal.open()
        this.isOpen = false
        this.slide = 'main'
      } else {
        this[modal] = true
      }
    },
    handleBack (slide) {
      this.slide = 'main'
      this[slide] = false
    },
    open (options = {}) {
      this.isOpen = true
      this.options = options
    },
    close (slide) {
      this.isOpen = false
      if (slide !== 'main') {
        this[slide] = false
        this.slide = 'main'
      }
    }
  }
}
</script>
