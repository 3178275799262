<template>
  <div>

    <div class="col-12 text-h4 text-weight-bold q-pa-sm text-center q-mt-lg q-mb-lg" >
      {{ $t('Welcome to') }} {{ appOptions.title }}
    </div>

    <quick-actions-panel v-if="isClient || isSupervisior || isAdministrator" :versionType=appOptions.versionType />

    <div class="col-12 text-h4 text-weight-bold q-pa-sm text-center q-mt-lg" >
      {{ $t('Connect your store') }}
    </div>

    <integration-grid type="7" link="ecommerce"/>

    <div class="q-my-lg" style="display: flex;"></div>

    <div class="col-12 text-h4 text-weight-bold q-pa-sm text-center q-mt-lg" >
      {{ $t('Enter your shipping/return address') }}
    </div>

    <div class="col-12 text-h6 text-weight-bold q-pa-sm text-center q-mt-lg text-nowrap" >
      <div>
        {{ $t('Current address') }} <span class="q-btn-group q-pa-md q-ml-md" style="width: 40%;display: inline-block;text-align: center;"> {{ this.address }} </span>
      </div>
    </div>

    <div @click="openSenderEdit(this.sender)" style="text-align: center;display: block;color: blue;text-decoration: underline;cursor: pointer;"> {{ $t('Add/Change address') }} </div>

    <div class="q-my-lg" style="display: flex;"></div>

    <div class="col-12 text-h4 text-weight-bold q-pa-sm text-center q-mt-lg" >
      {{ $t('Connect shipping carrier') }}
    </div>

    <integration-grid type="6" link="shipping"/>
  </div>
</template>
<script>
// Vuex
import {mapActions, mapGetters} from 'vuex'

// Components
import WarehouseTile from '../components/tiles/WarehouseTile.vue'
import DSTile from '../components/tiles/DSTile.vue'
import SourceTile from '../components/tiles/SourceTile.vue'
import SenderTile from '../components/tiles/SenderTile.vue'
import QuickActionsPanel from '../components/sections/QuickActionsPanel.vue'
import IntegrationGrid from '../components/main-page/IntegrationGrid.vue'

export default {
  components: {
    WarehouseTile,
    DSTile,
    SourceTile,
    SenderTile,
    QuickActionsPanel,
    IntegrationGrid
  },
  data () {
    return {
      userdata: JSON.parse(localStorage.getItem('userData')),
      legalEntity: null,
      entities: {},
      tileEntities: {},
      tiles: [],
      address: '',
      sender: null
    }
  },
  computed: {
    ...mapGetters([
      'appOptions',
      'isClient',
      'isSupervisior',
      'isAdministrator',
      'sources',
      'integrations'
    ]),
    isEnabledSettings () {
      return this.tiles.length > 0
    },
    time () {
      return this.tiles.length * 2
    }
  },
  mounted () {
    this.address =this.getUserSenderAddress(this.userdata.id)

    this.loadOwnerSources(this.userdata.id);
    this.loadIntegrations();

    if (!this.$utils.passWizardRequirments(this.userdata)) {
      return
    }

    this.loadMissingEntities()
      .then(() => {
        this.loadTiles()
      })
  },
  methods: {
    ...mapActions([
      'loadOwnerSources',
      'loadIntegrations'
    ]),
    handleSkip () {
      this.tiles = []
    },
    getDefaultData () {
      return window.appOptions.wizardTabs || {}
    },
    getTiles () {
      return Object.keys(this.getDefaultData())
    },
    getAction (type, data) {
      const actions = {
        shop: (defaultState = {}) => {
          const query = {
            per_page: 1,
            page: 1,
            filter: []
          }

          if (defaultState.type) {
            query.filter.push({ field: 'type', type: 'eq', value: defaultState.type })
          }

          return this.$service.shop.getAll(query)
        },
        warehouse: (defaultState = {}) => {
          const query = {
            per_page: 1,
            page: 1,
            filter: []
          }

          if (defaultState.type) {
            query.filter.push({ field: 'type', type: 'eq', value: defaultState.type })
          }

          return this.$service.warehouse.getAll(query)
        },
        sender: (defaultState = {}) => {
          const query = {
            per_page: 1,
            page: 1,
            filter: []
          }

          if (defaultState.state) {
            query.filter.push({ field: 'state', type: 'eq', value: defaultState.state })
          }

          return this.$service.sender.getAll(query)
        },
        sintegration: (defaultState = {}) => {
          const query = {
            per_page: 1,
            page: 1,
            filter: []
          }

          if (defaultState.state) {
            query.filter.push({ field: 'state', type: 'eq', value: defaultState.state })
          }

          return this.$service.iSource.getAll(query)
        },
        dsintegration: (defaultState = {}) => {
          const query = {
            per_page: 1,
            page: 1,
            filter: []
          }

          if (defaultState.state) {
            query.filter.push({ field: 'state', type: 'eq', value: defaultState.state })
          }

          return this.$service.deliveryServiceIntegration.getAll(query)
        }
      }

      return actions[type](data)
    },
    getNewEntities (entities, tiles, defaultState) {
      const actions = {
        warehouse: (defaultState = {}) => {
          const state = {
            ...defaultState,
            name: this.userdata.name,
            owner: this.userdata.id
          }

          state.type = this.userdata.roles.find(({ id }) => `${id}` === '8')
            ? 'virtual'
            : 'client'

          return state
        },
        sender: (defaultState = {}) => {
          return { ...defaultState }
        },
        sintegration: (defaultState = {}) => {
          return { ...defaultState }
        },
        dsintegration: (defaultState = {}) => {
          return { ...defaultState }
        }
      }

      // Extract only entities that we should create. If entity exist we dont need it
      return entities.reduce((acc, entity, index) => {
        if (entity.items.length <= 0) {
          acc.tiles.push(tiles[index])
          acc.entities[tiles[index]] = actions[tiles[index]](defaultState[tiles[index]])
        }

        return acc
      }, { tiles: [], entities: {} })
    },
    loadTiles () {
      const tiles = this.getTiles()
      const defaultState = this.getDefaultData()

      return Promise.all(tiles.map(type => this.getAction(type, defaultState[type])))
        .then(entities => {
          const newEntities = this.getNewEntities(entities, tiles, defaultState)

          // // If no entities this mean that all requered entities exists
          if (newEntities.tiles.length <= 0) {
            return this.updateUserdata(100)
          }

          this.tiles = newEntities.tiles
          this.tileEntities = newEntities.entities
        })
    },
    getAddress (profile) {
      const query = {
        page: 1,
        per_page: 25,
        filter: [
          { type: 'eq', field: 'profile', value: profile }
        ]
      }

      return this.$service.address.getAll(query)
        .then(({ items, totalItems }) => {
          if (totalItems > 0) {
            let address = items[0]._embedded?.locality?.name ? ((items[0]._embedded?.locality?.type ? (items[0]._embedded?.locality?.type + ' ') : '') + items[0]._embedded?.locality?.name + ', ') : ''
            address += items[0].street ? ((items[0].streetPrefix ? (items[0].streetPrefix + ' ') : '') + items[0].street + (items[0].house ? (' №' + items[0].house) : '')) : ''

            return address
          } else {
            return null
          }
        })
    },
    getUserSenderAddress (owner) {
      const query = {
        page: 1,
        per_page: 25,
        filter: [
          { type: 'eq', field: 'owner', value: owner }
        ]
      }

      return this.$service.sender.getAll(query)
        .then(({ items, totalItems }) => {
          let res = null
          if (totalItems > 0) {
            this.sender = items[0].id

            this.getAddress(items[0]._embedded.defaultSenderProfile.id)
              .then(result => {
                if (result !== null) {
                  this.address = result
                } else {
                  this.getAddress(items[0]._embedded.parent._embedded.defaultSenderProfile._links.self.href.split('/').pop())
                    .then(result => {
                      this.address = result
                    })
                }
              })
          }

          this.address = res
        })
    },
    handleSubmit (type) {
      this.tiles = this.tiles.filter(val => val !== type)
      const percentage = Math.ceil(100 / this.getTiles().length)
      const setup = (this.userdata.eav['users-app-setup'] || 0) + percentage

      return this.updateUserdata(setup)
    },
    updateUserdata (setup = 100) {
      return this.$service.user.save({ eav: { 'users-app-setup': setup } }, this.userdata.id)
        .then(user => {
          localStorage.setItem('userData', JSON.stringify(user))
        })
    },
    // Get entities which should be created automatic
    getEntities () {
      const entitiesByDomain = window.appOptions.automaticCreatedEntities || {}

      return entitiesByDomain.all || entitiesByDomain[window.appOptions.domain] || {}
    },
    // Check if entities exists
    loadMissingEntities () {
      let missingEntities = Object.keys(this.getEntities())
      const tiles = this.getTiles()

      // As a side effect from warehouse we create automatic sender and shop if user is client
      // Reason -> clients must have virtual warehouse, parent warehouse contains settings related with shop and sender
      if (tiles.includes('warehouse') && this.isClient) {
        missingEntities = missingEntities.filter(x => x !== 'sender' && x !== 'shop')
      }

      // We should check for this two accounts but dom reject requests if they are immediately send
      // For this we use one loader
      if (missingEntities.includes('depositAccount') && missingEntities.includes('paymentAccount')) {
        const keys = ['depositAccount', 'paymentAccount']
        const entities = missingEntities.filter(key => !keys.includes(key))
        entities.push('accounts')
        missingEntities = entities
      }

      const loaders = {
        sender: () => {
          const query = { per_page: 1, page: 1 }

          return this.$service.sender.getAll(query)
        },
        shop: () => {
          const query = { per_page: 1, page: 1 }

          return this.$service.shop.getAll(query)
        },
        accounts: () => {
          const query = {
            per_page: 1,
            page: 1,
            filter: [
              { type: 'eq', field: 'type', value: 'deposit' }
            ]
          }

          return this.$service.accounts.getAll(query)
            .then(({ items }) => {
              const secondQuery = {
                per_page: 1,
                page: 1,
                filter: [
                  { type: 'eq', field: 'type', value: 'payment' }
                ]
              }

              return Promise.all([items[0] || null, this.$service.accounts.getAll(secondQuery)])
                .then(result => {
                  return {
                    items: [result[0], result[1].items[0]].filter(val => val)
                  }
                })
            })
        },
        legalEntity: () => {
          const query = {
            per_page: 1,
            page: 1,
            filter: [
              { type: 'eq', field: 'type', value: 'customer' }
            ]
          }

          return this.$service.legalEntity.getAll(query)
        },
        depositAccount: () => {
          const query = {
            per_page: 1,
            page: 1,
            filter: [
              { type: 'eq', field: 'type', value: 'deposit' }
            ]
          }

          return this.$service.accounts.getAll(query)
        },
        paymentAccount: () => {
          const query = {
            per_page: 1,
            page: 1,
            filter: [
              { type: 'eq', field: 'type', value: 'payment' }
            ]
          }

          return this.$service.accounts.getAll(query)
        }
      }

      return Promise.all(missingEntities.map(entity => loaders[entity]()))
        .then(data => {
          let hasAccount = false

          // Set loaded entities as state
          this.entities = missingEntities.reduce((acc, entity, index) => {
            if (entity === 'accounts') {
              data[index].items.forEach(item => {
                acc[`${item.type}Account`] = item
              })

              hasAccount = true
              return acc
            }

            acc[entity] = data[index].items[0]
            return acc
          }, {})

          // if we have account this mean that we should check for deposit and payment account
          if (hasAccount) {
            missingEntities = missingEntities.filter(val => val !== 'accounts')

            if (!missingEntities.includes('depositAccount')) {
              missingEntities.push('depositAccount')
            }

            if (!missingEntities.includes('paymentAccount')) {
              missingEntities.push('paymentAccount')
            }
          }

          // Filter missing entities and call create function
          const entities = missingEntities.filter(key => !this.entities[key])
          return this.createMissingEntities(entities)
        })
    },
    createMissingEntities (keys) {
      if (keys.length === 0) {
        return Promise.resolve([])
      }

      const userdata = JSON.parse(localStorage.getItem('userData'))

      const actions = {
        sender: (defaultState = {}) => {
          const data = {
            ...defaultState,
            name: userdata.name
          }

          return this.$service.sender.save(data)
        },
        shop: (defaultState = {}) => {
          const data = {
            ...defaultState,
            name: userdata.name
          }

          return this.$service.shop.save(data)
        },
        legalEntity: (defaultState = {}) => {
          const data = {
            ...defaultState,
            name: userdata.name,
            owner: userdata.id
          }

          return this.$service.legalEntity.save(data)
        },
        depositAccount: (defaultState = {}) => {
          const data = {
            ...defaultState,
            name: userdata.name,
            customer: this.entities.legalEntity.id
          }

          return this.$service.accounts.save(data)
        },
        paymentAccount: (defaultState = {}) => {
          const data = {
            ...defaultState,
            name: userdata.name,
            customer: this.entities.legalEntity.id
          }

          return this.$service.accounts.save(data)
        }
      }

      const defaultState = this.getEntities()

      // Legal entity is required for accounts for this we create it first
      return Promise.resolve(keys.includes('legalEntity'))
        .then(shouldCreateLegalEntity => {
          keys = keys.filter(val => val !== 'legalEntity')

          return shouldCreateLegalEntity
            ? actions.legalEntity(defaultState.legalEntity)
            : this.entities.legalEntity
        })
        .then(legalEntity => {
          this.entities.legalEntity = legalEntity
          return Promise.all(keys.map(key => actions[key](defaultState[key])))
        })
    },
    openSenderEdit (sender) {
      this.$router.push(`/sender/entity/${sender}`)
    },
  }
}
</script>
