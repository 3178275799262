<template>
  <q-dialog
    v-model="isOpen"
    persistent
  >
    <q-card style="min-width: 50vw;">
      <q-card-section class="row items-center q-py-sm">
        <div class="text-h6">
          {{ $t('Select integration') }}
        </div>

        <q-space />

        <q-btn
          v-close-popup
          icon="close"
          flat
          round
          dense
        />
      </q-card-section>

      <q-card-section class="text-right">
        <q-btn
          color="light-blue-9"
          text-color="white"
          icon="add"
          size="sm"
          no-caps
          unelevated
          @click="handleAddNew"
        />
      </q-card-section>

      <q-card-section>
        <q-table
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="items"
          :columns="columns"
          v-model:pagination="pagination"
          virtual-scroll
          binary-state-sort
          flat
        >
          <template v-slot:body="props">
            <q-tr
              :props="props"
              class="clickable"
              @dblclick="onRowDblClick(props.row)"
            >
              <q-td
                key="id"
                :props="props"
                auto-width
              >
                {{ props.row.id }}
              </q-td>

              <q-td
                key="name"
                :props="props"
                class="text-subtitle2"
              >
                {{ props.row.name }}
              </q-td>

              <q-td
                key="state"
                :props="props"
              >
                <q-badge
                  :label="$t(props.row.state)"
                  :color="stateColors[props.row.state]"
                />
              </q-td>

              <q-td
                key="created"
                :props="props"
              >
                {{ $moment(props.row.created.date).format(appOptions.formats.date) }}
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>

      <q-card-actions align="center">
        <q-btn
          color="negative"
          :label="$t('Discard')"
          no-caps
          @click="handleClose"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

export default {
  name: 'IntegrationsModal',
  data () {
    return {
      stateColors: {
        active: 'positive',
        inactive: 'negative'
      },
      pagination: {
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      isOpen: false,
      items: [],
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ])
  },
  methods: {
    resolve () {},
    reject () {},
    show (items = []) {
      this.items = items
      this.isOpen = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    handleClose () {
      this.isOpen = false
      this.resolve(null)
    },
    handleAddNew () {
      this.isOpen = false
      this.resolve(null)
    },
    onRowDblClick (item) {
      this.isOpen = false
      this.resolve(item)
    }
  }
}
</script>
